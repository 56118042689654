const colors = {
  green: '#25ae4e',
  red: '#ff0033',
  black: '#333333',
  grey: '#879299',
  yellow: '#ffcc00',
  white: '#ffffff',
  darkGrey: '#7c7c80',
  darkBlue: '#223268',
  navy: '#005FAE',
}

const baseSize = 'lg'

const baseStyle = 'shrink-0 outline-none'

const sizes = {
  xs: 'w-3 h-3',
  sm: 'w-4 h-4',
  md: 'w-5 h-5',
  lg: 'w-6 h-6',
  xl: 'w-8 h-8',
  '2xl': 'w-10 h-10',
  '3xl': 'w-12 h-12',
  '4xl': 'size-13',
}

export { colors, baseSize, baseStyle, sizes }
